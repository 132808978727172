import React, { useState } from 'react'
import { Button, notification, Spin } from 'antd'
import FormContainer from './FormContainer'
import { getAuth } from '../http'

const navigateToLogin = setLoading => {
  notification.warning({
    message: 'Your session has expired',
    description: 'Redirecting you to the login page...'
  })
  setTimeout(() => {
    setLoading(false)
    window.location.href = '/login'
  }, 1000)
}

const FormWrapper = () => {
  const [loading, setLoading] = useState(true)

  const checkAuthStatus = async () => {
    await getAuth()
      .then(data => {
        if (
          !data ||
          !data?.clientPrincipal ||
          !data?.clientPrincipal?.userRoles?.includes('authenticated')
        ) {
          // session expired, redirect to login
          navigateToLogin(setLoading)
        } else {
          setLoading(false)
        }
      })
      .catch(error => {
        console.error('Error checking auth status:', error)
        navigateToLogin(setLoading)
      })
  }

  return (
    <Spin
      indicator={null}
      tip={
        <Button type='primary' size='large' onClick={checkAuthStatus}>
          Start
        </Button>
      }
      spinning={loading}
    >
      <FormContainer />
    </Spin>
  )
}

export default FormWrapper
